// 总视图
<template>
    <!-- 今日数据统计 -->
    <el-card shadow="never"
             class="blueLine todayPlanBox">
        <div slot="header">
            <span>
                今日计划
            </span>
            <el-button type="primary"
                       size="default"
                       @click="openpopAddPlan">新建计划</el-button>
        </div>
        <div class="taskBoxBody">
            <div class="notFinish taskBox">
                <div class="warp"
                     v-if="todayPlanList.notCompletedPlans && todayPlanList.notCompletedPlans.length>0">
                    <el-row :gutter="20"
                            v-for="(item,idx) in todayPlanList.notCompletedPlans"
                            :key="idx"
                            class="taskItem">
                        <el-col :span="8">{{item.planTime}}</el-col>
                        <el-col :span="12"><div class="contentItem"> {{item.content || ""}}</div></el-col>
                        <el-col :span="4"
                                class="switshBox ofh">
                            <el-switch v-model="item.status"
                                       active-color="#13ce66"
                                       class="flr"
                                       :active-value="true"
                                       :inactive-value="false"
                                       @change="planChange(item,true)"
                                       inactive-color="#E7E7E7"></el-switch>
                        </el-col>
                    </el-row>
                </div>
                <div class="emptyBg"
                     style="height: 100%"
                     v-else></div>
            </div>
            <div class="isFinish taskBox">
                <div class="warp"
                     v-if="todayPlanList.completedPlans && todayPlanList.completedPlans.length>0">
                    <el-row :gutter="20"
                            v-for="(item,idx) in todayPlanList.completedPlans"
                            :key="idx"
                            class="taskItem">
                        <el-col :span="8">{{item.planTime}}</el-col>
                        <el-col :span="12"> <div class="contentItem"> {{item.content || ""}}</div> </el-col>
                        <el-col :span="4"
                                class="ofh switshBox">
                            <el-switch v-model="item.status"
                                       active-color="#13ce66"
                                       class="flr"
                                       :active-value="true"
                                       :inactive-value="false"
                                       @change="planChange(item,false)"
                                       inactive-color="#E7E7E7"></el-switch>
                        </el-col>
                    </el-row>
                </div>
                <div class="emptyBg"
                     style="height: 100%"
                     v-else></div>
            </div>
        </div>
        <!-- 新建计划 -->
        <pop-add-plan v-if="popAddPlanVisible"
                      @refresh="getTodayPlanList"
                      @close="closepopAddPlan"></pop-add-plan>
    </el-card>

</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/generalView.js";
import PopAddPlan from "./popAddPlan";
export default {
    name: "comTodayPlan",

    props: [],

    components: {
        PopAddPlan,
    },

    data() {
        return {
            popAddPlanVisible: false, // 新建计划弹窗显示
            todayPlanList: [], // 今日计划列表
        };
    },

    created() {},

    mounted() {
        // // 今日计划列表
        this.getTodayPlanList();
    },

    methods: {
        // 今日计划列表
        getTodayPlanList() {
            let loading = Loading.service({
                target: document.querySelector(".todayPlanBox"),
            });
            API.getTodayPlanList()
                .then((res) => {
                    loading.close();
                    this.todayPlanList = res.content; // 今日计划列表
                })
                .catch(() => {
                    loading.close();
                });
        },

        //计划切换
        planChange(data, status) {
            let loading = Loading.service({
                target: document.querySelector(".taskBoxBody"),
            });
            API.saveAddPlan(
                Object.assign({}, data, {
                    status: status,
                })
            )
                .then(() => {
                    this.$message({
                        message: "更新成功！",
                        type: "success",
                    });
                    loading.close();
                    this.getTodayPlanList();
                })
                .catch(() => loading.close());
        },
        // 新建计划 打开弹框
        openpopAddPlan() {
            this.popAddPlanVisible = true;
        },
        // 新建计划 关闭弹框
        closepopAddPlan() {
            this.popAddPlanVisible = false;
        },
    },
    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.generalView ::v-deep .el-card__header {
    padding: 0 30px;
    height: 78px;
    line-height: 78px;
    font-size: 18px;
    font-weight: bold;
    color: #262626;
}
// 今日计划
.todayPlanBox .el-card__header .el-button {
    width: 80px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #00a2e9;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #00a2e9;
    float: right;
    margin-top: 20px;
    padding: 0;
}
.todayPlanBox ::v-deep .el-card__body {
    height: 730px;
}
.todayPlanBox .notFinish,
.todayPlanBox .isFinish {
    height: 330px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
    padding: 16px 0;
    overflow-y: auto;
}
.todayPlanBox .notFinish .warp,
.todayPlanBox .isFinish .warp {
    padding: 0 16px;
    max-height: 100%;
    overflow-y: auto;
}
.todayPlanBox .notFinish {
    margin-bottom: 30px;
}
.todayPlanBox .taskItem {
    min-height: 48px;
    line-height: 48px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 10px;
}
.todayPlanBox .taskItem .switshBox {
    padding-top: 12px;
}

.contentItem{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    line-height: 28px;
    min-height: 48px;
}
</style>
