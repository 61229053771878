// 总视图  generalView
// import request from '@/utils/request'
import {
    get,
    // post,
    postForm,
    postJson,
} from "./http/http";

const httpServer = {
    // 总视图 查询公司整体利润
    getTotalCoat() {
        return postForm("/report/total-view/profit");
    },
    // 今日数据统计
    getTodayData() {
        return get("/report/total-view/today-data-report");
    },
    // 项目机会三个echart
    getItemOpportunityEchart() {
        return get("/report/total-view/reserve-project-report");
    },
    // 项目统计
    getProjectReport() {
        return get("/report/total-view/project-report");
    },
    // 新建计划
    saveAddPlan(params) {
        return postJson("/report/plan/save", params);
    },
    // 今日计划列表
    getTodayPlanList() {
        return get("/report/total-view/today-plan-report");
    },
    // 今日工时
    getTodayHours() {
        return get("/workTask/work-task-hours/todayHours");
    },
    //获取合同状态
    getcontractEchart(){
        return postForm("/report/total-view/contractMoney");
    },
    //总统计视图任务列表列表
    getIndexTaskTable(params){
        return postJson("/workTask/work-task/indexTaskTable",params);
    },

    //删除任务
    deleteTask(id){
        return get(`/workTask/work-task/delete/${id}`);
    },
};

export default httpServer;
