// 总视图
<template>
    <!-- 成本统计模块 -->
    <div class="blueLine statistics">
        <el-row :gutter="20">
            <el-col :span="7">
                <div class="title">利润（元）</div>
                <el-tooltip class=""
                            effect="dark"
                            :content="getYear+'年利润'"
                            placement="left-start">
                    <div class="count">{{totalCoat.profitMoney || '0'}}</div>
                </el-tooltip>
            </el-col>
            <el-col :span="7">
                <div class="title">收入（元）</div>

                <el-tooltip class=""
                            effect="dark"
                            content="合同收入"
                            placement="left-start">
                    <div class="count">{{totalCoat.incomeMoney || '0'}}</div>
                </el-tooltip>
            </el-col>
            <el-col :span="10">
                <div class="title">成本（元）</div>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <!-- 人工成本 -->
                        <el-tooltip class=""
                                    effect="dark"
                                    content="人工成本"
                                    placement="left-start">
                            <div class="count person">{{totalCoat.personProfitMoney || '0'}}</div>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="8">
                        <!-- 合同成本 -->
                        <el-tooltip class=""
                                    effect="dark"
                                    content="合同成本"
                                    placement="left-start">
                            <div class="count">{{totalCoat.contractProfitMoney || '0'}}</div>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="8">
                        <!-- 其他成本 -->
                        <el-tooltip class=""
                                    effect="dark"
                                    content="其他成本"
                                    placement="left-start">
                            <div class="count item">{{totalCoat.otherProfitMoney || '0'}}</div>
                        </el-tooltip>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>

</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/generalView.js";
export default {
    name: "generalViewProfits",

    props: [],

    components: {},

    data() {
        return {
            totalCoat: {}, // 公司整体利润
        };
    },

    created() {},

    mounted() {
        // // 查询公司整体利润
        this.getTotalCoat();
    },

    methods: {
        // 查询公司整体利润
        getTotalCoat() {
            let loading = Loading.service({
                target: document.querySelector(".statistics"),
            });
            API.getTotalCoat()
                .then((res) => {
                    loading.close();
                    this.totalCoat = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
    },
    computed: {
        getYear() {
            return new Date().getFullYear();
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.generalView ::v-deep .el-card__header {
    padding: 0 30px;
    height: 78px;
    line-height: 78px;
    font-size: 18px;
    font-weight: bold;
    color: #262626;
}
// 成本统计模块
.statistics {
    height: 139px;
    background: #ffffff;
    border-radius: 2px;
    padding: 30px;
    margin-bottom: 30px;
}
.statistics .title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #262626;
    margin-bottom: 20px;
}
.statistics .count {
    font-size: 30px;
    height: 42px;
    line-height: 42px;
    font-family: DIN;
    font-weight: 500;
    color: #222222;
    padding-left: 62px;
    background: url("../../../assets/imgs/icon_statistics1.png") no-repeat 0 0;
    background-size: 42px;
}
.statistics .count.person {
    background-image: url("../../../assets/imgs/icon_statistics2.png");
}
.statistics .count.item {
    background-image: url("../../../assets/imgs/icon_statistics3.png");
}
</style>
