// 总视图
<template>
        <!-- 头部logo -->
        <div class="logoBox ofh">
            <div class="fll">
                <p class="nameBox">
                    <span class="name">万物理想</span>
                    <span class="englishName">WIDEAL</span>
                </p>
                <div class="time">2019年12月17日 — 至今</div>
            </div>
            <div class="flr">
                <div class="nowTime">{{nowTime}}</div>
                <div class="yearMonthDay">{{today}}</div>
            </div>
        </div>

</template>

<script>

export default {
    name: "generalViewHeader",

    props: [],

    components: {

    },

    data() {
        return {
            test1: "",
            timeId: "", // 当前定时器id
            nowTime: "", // 当前时间
            today: "", // 当前日期
        };
    },

    created() {},

    mounted() {
        this.today = this.$moment().format("yyyy年MM月DD日"); //当前日期
        this.nowTime = this.$moment().format("HH:mm:ss"); //当前时间
        //创建定时器更新最新的时间
        this.timeId = setInterval(() => {
            this.nowTime = this.$moment().format("HH:mm:ss"); //当前时间
        }, 1000);


    },
    beforeDestroy: function () {
        //实例销毁前清除定时器
        if (this.timeId) {
            clearInterval(this.timeId);
        }
    },
    methods:{},
    computed: {

    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.generalView ::v-deep .el-card__header {
    padding: 0 30px;
    height: 78px;
    line-height: 78px;
    font-size: 18px;
    font-weight: bold;
    color: #262626;
}
// logo
.logoBox {
    height: 72px;
    padding-left: 92px;
    margin-bottom: 30px;
    background: url("../../../assets/imgs/icon_logo.png") no-repeat 0 center;
}
.logoBox .fll {
    font-size: 18px;
    font-weight: 400;
    color: #565656;
}
.nameBox {
    margin: 0 0 16px 0;
}
.logoBox .name {
    font-size: 30px;
    font-family: zcool-gdh;
    font-weight: 400;
    font-style: italic;
    color: #262626;
    margin-right: 18px;
}
.logoBox .englishName {
    font-size: 30px;
    font-family: HuXiaoBo_KuHei;
    font-weight: 400;
    font-style: italic;
    color: #262626;
}
.nowTime {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #262626;
    text-align: right;
    margin-bottom: 16px;
}
.yearMonthDay {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}
</style>
