// 总视图
<template>
    <!-- 项目 -->
    <el-card shadow="never"
             class="blueLine">
        <div slot="header">
            <span>项目</span>
        </div>
        <div class="warp ofh projectReport">
            <div class="fll mr15 relativeBox">
                <div class="fullBox"
                     id="itemEchart1"></div>
                <div class="centerTipsBox tipsThree">{{projectTjOne}}<span class="dwSmall"></span> </div>
            </div>
            <div class="fll relativeBox itemEchart2">
                <div class="fullBox"
                     id="itemEchart2"></div>
                <div class="centerTipsBox tipsThree">{{projectTjTwo}}<span class="dwSmall"></span> </div>
            </div>
        </div>
    </el-card>

</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/generalView.js";
export default {
    name: "comPorject",

    props: [],

    components: {},

    data() {
        return {
            projectStatusReportVos: [], //项目状态统计
            projectDeptReportVos: [], //项目部门统计
        };
    },

    created() {},

    mounted() {
        // // 项目统计
        this.getProjectReport();
    },

    methods: {
        // 项目统计
        getProjectReport() {
            let loading = Loading.service({
                target: document.querySelector(".projectReport"),
            });
            API.getProjectReport()
                .then((res) => {
                    loading.close();
                    this.projectStatusReportVos =
                        res.content.projectStatusReportVos;
                    this.projectDeptReportVos =
                        res.content.projectDeptReportVos;
                    this.itemEchart1();
                    this.itemEchart2();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 项目1
        itemEchart1() {
            const that = this;
            let xArr = this.projectStatusReportVos.map(
                (v) => v.projectStatusName
            );
            let data = this.projectStatusReportVos.map((v) => {
                return {
                    name: v.projectStatusName,
                    value: v.statusCount,
                };
            });
            let todaydayHoursView = this.$echarts.init(
                document.getElementById("itemEchart1")
            );
            let option = {
                tooltip: {
                    trigger: "item",
                    formatter(v) {
                        return `${v.name}：${v.value}<br/>（${(
                            (v.value / that.projectTjOne) *
                            100
                        ).toFixed(2)}%）`;
                    },
                    position: function (point) {
                        return [point[0] - 40, point[1] + 30];
                    },
                },
                legend: [
                    {
                        x: "center",
                        bottom: 10,
                        textStyle: {
                            color: "#9A9A9A",
                        },
                        data: xArr,
                    },
                ],
                series: [
                    {
                        type: "pie",
                        radius: ["70%", "40%"],
                        center: ["50%", "40%"],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        label: {
                            normal: {
                                show: true,
                                position: "inner",
                                formatter(v) {
                                    return `${v.value}\n（${(
                                        (v.value / that.projectTjOne) *
                                        100
                                    ).toFixed(2)}%）`;
                                },
                            },
                            emphasis: {
                                show: true,
                                textStyle: {
                                    fontSize: "12",
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: data,
                    },
                ],
                color: ["#60D68A", "#B179ED", "#FBD83C", "#48B6FD", "#FCA847"],
            };
            todaydayHoursView.setOption(option);
        },
        // 项目2
        itemEchart2() {
            const that = this;
            let xArr = this.projectDeptReportVos.map((v) => v.deptName);
            let data = this.projectDeptReportVos.map((v) => {
                return {
                    name: v.deptName,
                    value: v.projectCount,
                };
            });
            let todaydayHoursView = this.$echarts.init(
                document.getElementById("itemEchart2")
            );
            let option = {
                tooltip: {
                    trigger: "item",
                    formatter(v) {
                        return `${v.name}：${v.value}<br/>（${(
                            (v.value / that.projectTjTwo) *
                            100
                        ).toFixed(2)}%）`;
                    },
                },
                legend: [
                    {
                        x: "center",
                        bottom: 10,
                        textStyle: {
                            color: "#9A9A9A",
                        },
                        data: xArr,
                    },
                ],
                series: [
                    {
                        type: "pie",
                        radius: ["70%", "40%"],
                        center: ["50%", "40%"],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        label: {
                            normal: {
                                show: true,
                                position: "inner",
                                formatter(v) {
                                    return `${v.value}\n（${(
                                        (v.value / that.projectTjTwo) *
                                        100
                                    ).toFixed(2)}%）`;
                                },
                            },
                            emphasis: {
                                show: true,
                                textStyle: {
                                    fontSize: "12",
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: data,
                    },
                ],
                color: ["#F67D96", "#FCA847", "#B179ED", "#2292DD"],
            };
            todaydayHoursView.setOption(option);
        },
    },
    computed: {
        //项目数量统计
        projectTjOne() {
            return (
                eval(
                    this.projectStatusReportVos
                        .map((v) => v.statusCount)
                        .join("+")
                ) || 0
            );
        },

        //部门数量统计
        projectTjTwo() {
            return (
                eval(
                    this.projectDeptReportVos
                        .map((v) => v.projectCount)
                        .join("+")
                ) || 0
            );
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// 项目机会
.itemOpportunityBox ::v-deep .el-card__body {
    padding-bottom: 30px;
    overflow: hidden;
}
.itemOpportunity {
    width: 246px;
    height: 142px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
}
#itemOpportunityEchart1,
.itemOpportunityEchart1 {
    height: 305px;
}
.fullBox {
    width: 100%;
    height: 100%;
    z-index: 3;
}
.relativeBox {
    position: relative;
}
#itemEchart1,
.itemEchart2 {
    width: 246px;
    height: 305px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
}
#todayHoursEchart {
    height: 305px;
}
.centerTipsBox {
    position: absolute;
    font-size: 20px;
    color: #000;
    width: 100%;
    text-align: center;
    z-index: 0;
}
.centerTipsBox.tipsone {
    top: 37%;
    left: 0%;
}
.centerTipsBox.tipstwo {
    top: 43%;
    left: -15%;
}

.centerTipsBox.tipsThree {
    top: 37%;
    left: 40%;
    z-index: 4;
    width: 20%;
}
.dwSmall {
    font-size: 16px;
}
// 适应小屏幕
@media screen and (max-width: 1685px) {
    .projectReport .fll,
    .projectReport .flr {
        width: 100%;
    }
    #itemEchart1{
        margin-bottom: 15px;
    }
    #itemEchart1,.fullBox {
        width: 100%;
    }
    .projectReport ::v-deep canvas {
        margin: 0 auto!important;
    }
}
</style>
