// 总视图
<template>
    <!-- 项目机会 -->
    <el-card shadow="never"
             class="blueLine itemOpportunityBox">
        <div slot="header">
            <span>项目机会</span>
        </div>
        <div class="warp getItemOpportunityEchart">
            <div class="fll itemOpportunity relativeBox itemOpportunityEchart1">
                <div class="fullBox"
                     id="itemOpportunityEchart1"></div>
                <div class="centerTipsBox tipsone">{{projectJhOne}}<span class="dwSmall"></span> </div>
            </div>
            <div class="flr">
                <div class="itemOpportunity mb15 relativeBox">
                    <div class="fullBox"
                         id="itemOpportunityEchart2"></div>
                    <div class="centerTipsBox tipstwo">{{projectConversion}}<span class="dwSmall"></span> </div>
                </div>
                <div class="itemOpportunity"
                     id="itemOpportunityEchart3"></div>
            </div>
        </div>
    </el-card>

</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/generalView.js";
export default {
    name: "comProjectOpportunities",

    props: [],

    components: {},

    data() {
        return {
            reportVos: [], // 项目类型统计
            rateVo: {}, // 项目机会转化情况统计
            rateReports: [], // 前六个月项目机会转化率统计
        };
    },

    created() {},

    mounted() {
        // // 项目机会三个echart
        this.getItemOpportunityEchart();
    },

    methods: {
        // 项目机会三个echart
        getItemOpportunityEchart() {
            let loading = Loading.service({
                target: document.querySelector(".itemOpportunityBox"),
            });
            API.getItemOpportunityEchart()
                .then((res) => {
                    loading.close();
                    this.reportVos = res.content.reportVos.map((v) => {
                        return {
                            name: v.reserveProjectTypeName,
                            value: v.typeCount,
                        };
                    }); // 项目类型统计
                    this.rateVo = res.content.rateVo; // 项目机会转化情况统计
                    this.rateReports = res.content.rateReports; // 前六个月项目机会转化率统计
                    // 项目机会1
                    this.itemOpportunity1();
                    // 项目机会2
                    this.itemOpportunity2();
                    // 项目机会3
                    this.itemOpportunity3();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 项目机会1
        itemOpportunity1() {
            const that = this;
            let xArr = this.reportVos.map((v) => v.name);
            let data = this.reportVos;
            let todaydayHoursView = this.$echarts.init(
                document.getElementById("itemOpportunityEchart1")
            );
            let option = {
                tooltip: {
                    trigger: "item",
                    formatter(v) {
                        return `${v.name}：${v.value}\n（${((v.value / that.projectJhOne) * 100).toFixed(2)}%）`;
                    },
                    position: function (point) {
                        return [point[0] - 30, point[1] + 30];
                    },
                },
                legend: {
                    // orient: "vertical",
                    bottom: 10,
                    textStyle: {
                        color: "#9A9A9A",
                    },
                    data: xArr,
                },
                series: [
                    {
                        type: "pie",
                        radius: ["70%", "40%"],
                        center: ["50%", "40%"],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        label: {
                            normal: {
                                show: true,
                                position: "inner",
                                formatter(v) {
                                    // return `${v.value}`;
                                    return `${v.value}\n（${((v.value / that.projectJhOne) * 100).toFixed(2)}%）`;
                                },
                            },
                            emphasis: {
                                show: true,
                                textStyle: {
                                    fontSize: "12",
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: true,
                                length: 5,
                                length2: 5,
                            },
                        },
                        data: data,
                    },
                ],
                color: ["#FFB708", "#3EA8FF", "#48D781", "#F17904"],
            };
            todaydayHoursView.setOption(option);
        },
        // 项目机会2
        itemOpportunity2() {
            const that = this;
            let xArr = ["废止", "转化"];
            let data = [
                { value: this.rateVo.abolishedCount, name: "废止" },
                { value: this.rateVo.conversionCount, name: "转化" },
            ];
            let todaydayHoursView = this.$echarts.init(
                document.getElementById("itemOpportunityEchart2")
            );
            let option = {
                tooltip: {
                    trigger: "item",
                    formatter(v) {
                        return `${v.name}：${v.value}\n（${((v.value / that.projectConversionNum) * 100).toFixed(2)}%）`;
                    },
                },
                legend: {
                    orient: "vertical",
                    right: 0,
                    y: "center",
                    textStyle: {
                        color: "#9A9A9A",
                    },
                    data: xArr,
                },
                series: [
                    {
                        type: "pie",
                        radius: ["50%", "80%"],
                        center: ["35%", "50%"],
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                show: true,
                                position: "inner",
                                formatter(v) {
                                    return `${v.value}\n（${((v.value / that.projectConversionNum) * 100).toFixed(2)}%）`;
                                },
                            },
                            emphasis: {
                                show: true,
                                textStyle: {
                                    fontSize: "12",
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: true,
                                length: 5,
                                length2: 5,
                            },
                        },
                        data: data,
                    },
                ],
                color: ["#4EA9FF", "#42CBF4"],
            };
            todaydayHoursView.setOption(option);
        },
        // 项目机会3
        itemOpportunity3() {
            let xArr = this.rateReports.map((v) => v.month + "月");
            let item1Arr = this.rateReports.map((v) => v.rate);
            let todaydayHoursView = this.$echarts.init(
                document.getElementById("itemOpportunityEchart3")
            );
            let option = {
                backgroundColor: "#fff",
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        lineStyle: {
                            color: "#57617B",
                        },
                    },
                    formatter(res) {
                        let v = res[0];
                        return `${v.name}:${v.value * 100}%`;
                    },
                },
                grid: {
                    top: "30",
                    bottom: "15",
                    left: "0",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: "20%", // 离x轴的距离
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            fontSize: 12,
                            color: "#808080",
                        },
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#ddd",
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: "solid",
                            color: "#ddd",
                        },
                    },
                    data: xArr,
                },
                yAxis: {
                    show: true,
                    axisTick: {
                        show: false,
                    },
                    type: "value",
                    name: "转化率",
                    // interval: 10, //间隔
                    // nameRotate: '0.1',
                    // minInterval: 10,
                    nameTextStyle: {
                        color: "#808080",
                        padding: [0, 0, -5, 0],
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#ddd",
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            fontSize: 10,
                            color: "#808080",
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: "solid",
                            color: "#ddd",
                        },
                    },
                },
                series: {
                    name: "转化率",
                    type: "line",
                    smooth: true,
                    symbol: "emptycircle",
                    symbolSize: 10,
                    showSymbol: true,
                    label: {
                        normal: {
                            show: true,
                            position: "top",
                            formatter(v) {
                                return `${v.value * 100}%`;
                            },
                            textStyle: {
                                color: "#000",
                            },
                        },
                    },
                    lineStyle: {
                        normal: {
                            width: 3,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: "#17C9B8",
                        },
                    },
                    data: item1Arr,
                },
            };
            todaydayHoursView.setOption(option);
        },
    },
    computed: {
        //项目机会数量
        projectJhOne() {
            return eval(this.reportVos.map((v) => v.value).join("+")) || 0;
        },

        //机会转化率
        projectConversion() {
            return (
                (
                    this.rateVo.conversionCount /
                        (this.rateVo.abolishedCount +
                            this.rateVo.conversionCount) || 0
                ).toFixed(2) *
                    100 +
                "%"
            );
        },
        //项目机会总数
        projectConversionNum() {
            return (
                (this.rateVo.abolishedCount +
                            this.rateVo.conversionCount) || 0
            );
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// 项目机会
.itemOpportunityBox ::v-deep .el-card__body {
    padding-bottom: 30px;
    overflow: hidden;
}
.itemOpportunity {
    width: 246px;
    height: 142px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
}
#itemOpportunityEchart1,
.itemOpportunityEchart1 {
    height: 305px;
}
.fullBox {
    width: 100%;
    height: 100%;
    z-index: 3;
}
.relativeBox {
    position: relative;
}
#itemEchart1,
.itemEchart2 {
    width: 246px;
    height: 305px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
}
#todayHoursEchart {
    height: 305px;
}
.centerTipsBox {
    position: absolute;
    font-size: 20px;
    color: #000;
    width: 100%;
    text-align: center;
    z-index: 0;
}
.centerTipsBox.tipsone {
    top: 37%;
    left: 0%;
}
.centerTipsBox.tipstwo {
    top: 43%;
    left: -15%;
}

.centerTipsBox.tipsThree {
    top: 37%;
    left: 40%;
    z-index: 4;
    width: 20%;
}
.dwSmall {
    font-size: 16px;
}
// 适应小屏幕
@media screen and (max-width: 1685px) {
    .getItemOpportunityEchart .fll,
    .getItemOpportunityEchart .flr{
        width: 100%;
    }
    .fullBox,.itemOpportunity{
        width: 100%;
    }
    .getItemOpportunityEchart canvas{
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
