// 新建计划
// popAddPlan
<template>

    <div class="popAddPlan">
        <el-dialog title="新建计划"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-width="80px"
                     :inline="false">
                <el-form-item label="计划时间"
                              prop="planTime">
                    <el-time-picker v-model="form.planTime"
                                    :editable="false"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }"
                                    placeholder="请选择计划时间">
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="计划内容"
                              prop="content">
                    <el-input type="textarea"
                              v-model="form.content"
                              rows="5"
                              placeholder="请输入计划内容"></el-input>
                </el-form-item>
                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               class="saveBtn"
                               @click="save">保存</el-button>
                </div>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/generalView.js";
export default {
    name: "popAddPlan",

    props: [],

    components: {},

    data() {
        return {
            dialogVisible: true,
            form: {
                planTime: "", // 计划时间
                content: "", // 计划内容
            },
            formRules: {
                planTime: [
                    {
                        required: true,
                        message: "请选择计划时间",
                    },
                ], // 计划时间
                content: [
                    {
                        required: true,
                        message: "请输入计划内容",
                    },
                ], // 计划内容
            },
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 保存
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(".saveBtn"),
                    });
                    API.saveAddPlan(this.form)
                        .then(() => {
                            loading.close();
                            this.$message({
                                message: "保存成功！",
                                type: "success",
                            });
                            this.$emit("refresh");
                            this.close();
                        })
                        .catch((err) => {
                            console.log(err);
                            loading.close();
                        });
                } else {
                    this.$message({
                        message: "请检查必填信息是否填写完整！",
                        type: "error",
                    });
                    return false;
                }
            });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>