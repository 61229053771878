// 总视图
<template>
    <!-- 合同 -->
        <el-card shadow="never"
                 class="contractBox">
            <div slot="header">
                <span>合同</span>
            </div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="warp">
                        <div class="title ofh">
                            <div class="name fll">收入</div>
                            <div class="tip flr">
                                <span class="blue mr30">已收金额（元）</span>
                                <span class="green">未收金额（元）</span>
                            </div>
                        </div>
                        <div class="lineBox ofh">
                            <div class="fll">
                                <el-progress :show-text="false"
                                             class="greenbg"
                                             :stroke-width="16"
                                             color="#4EA9FF"
                                             :percentage="contractEchart.alreadyIncomeMoney/(contractEchart.alreadyIncomeMoney + contractEchart.notIncomeMoney)* 100 || 0"></el-progress>
                            </div>
                            <div class="flr">{{contractEchart.alreadyIncomeMoney || 0}} / {{contractEchart.notIncomeMoney || 0}}</div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="warp">
                        <div class="title ofh">
                            <div class="name fll">支出</div>
                            <div class="tip flr">
                                <span class="blue mr30">已付金额（元）</span>
                                <span class="orange">未付金额（元）</span>
                            </div>
                        </div>
                        <div class="lineBox ofh">
                            <div class="fll">
                                <el-progress :show-text="false"
                                             class="orangebg"
                                             :stroke-width="16"
                                             color="#4EA9FF"
                                             :percentage="contractEchart.alreadyPayMoney/(contractEchart.alreadyPayMoney + contractEchart.notPayMoney) * 100"></el-progress>
                            </div>
                            <div class="flr">{{contractEchart.alreadyPayMoney || 0}} / {{contractEchart.notPayMoney || 0}}</div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-card>

</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/generalView.js";
export default {
    name: "comContract",

    props: [],

    components: {},

    data() {
        return {
            contractEchart: {}, // 合同进度图
        };
    },

    created() {},

    mounted() {
        // // 合同进度图
        this.getcontractEchart();
    },

    methods: {
        // 合同进度图
        getcontractEchart() {
            let loading = Loading.service({
                target: document.querySelector(".contractBox"),
            });
            API.getcontractEchart()
                .then((res) => {
                    loading.close();
                    this.contractEchart = res.content; // 合同进度图
                })
                .catch(() => {
                    loading.close();
                });
        },
    },
    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// 合同
.contractBox .warp {
    height: 130px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
}
.contractBox .warp .title {
    height: 33px;
    line-height: 33px;
    background: #f3f3f3;
    border-radius: 2px;
    padding: 0 16px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #262626;
}
.contractBox .tip span {
    color: #262626;
    padding-left: 14px;
    position: relative;
    display: inline-block;
}
.contractBox .tip span:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4ea9ff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.contractBox .tip .green:after {
    background: #2edac2;
}
.contractBox .tip .orange:after {
    background: #ffb708;
}
.lineBox {
    height: 97px;
    padding: 40px 17px;
}
.lineBox .flr {
    width: 150px;
    text-align: center;
    height: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #595959;
}
.lineBox .fll {
    width: calc(100% - 170px);
}
.lineBox .greenbg ::v-deep .el-progress-bar__outer {
    background: #2edac2;
}
.lineBox .orangebg ::v-deep .el-progress-bar__outer {
    background: #ffb708;
}
.lineBox ::v-deep .el-progress-bar__inner {
    border-radius: 8px 0px 0px 8px;
}
</style>
