// 总视图
<template>

    <div class="generalView baseBg">

        <!-- 头部logo -->
        <comHeader></comHeader>

        <!-- 利润 -->
        <comProfits></comProfits>

        <el-row :gutter="30"
                class="mb30">
            <el-col :span="14">
                <!-- 任务列表 -->
                <comTaskList></comTaskList>

                <!-- 今日数据统计 -->
                <comTodayData></comTodayData>
            </el-col>

            <el-col :span="10">
                <!-- 今日计划 -->
                <comTodayPlan></comTodayPlan>
            </el-col>
        </el-row>

        <el-row :gutter="20"
                class="mb30 ">
            <el-col :span="8">

                <!-- 项目机会 -->
                <comProjectOpportunities></comProjectOpportunities>

            </el-col>
            <el-col :span="8">

                <!-- 项目 -->
                <comPorject></comPorject>

            </el-col>
            <el-col :span="8">
                <!-- 今日工时 -->
                <comTodayHours></comTodayHours>

            </el-col>
        </el-row>

        <!-- 合同 -->
        <comContract></comContract>

    </div>

</template>

<script>
import comHeader from "./components/comHeader"; //头部
import comProfits from "./components/comProfits"; //利润
import comTaskList from "./components/comTaskList"; //任务
import comTodayData from "./components/comTodayData"; //今日数据统计
import comTodayPlan from "./components/comTodayPlan"; //今日计划
import comProjectOpportunities from "./components/comProjectOpportunities"; //项目机会
import comPorject from "./components/comPorject"; //项目统计
import comTodayHours from "./components/comTodayHours"; //今日工时
import comContract from "./components/comContract"; //合同
// import API from "@/api/generalView.js";
// import { Loading } from "element-ui";
export default {
    name: "generalView",
    props: [],

    components: {
        comProfits,
        comHeader,
        comTaskList,
        comTodayData,
        comTodayPlan,
        comProjectOpportunities,
        comPorject,
        comTodayHours,
        comContract,
    },

    data() {
        return {};
    },

    created() {},

    mounted() {
    },
    beforeDestroy: function () {},
    methods: {},

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.generalView ::v-deep .el-card__header {
    padding: 0 30px;
    height: 78px;
    line-height: 78px;
    font-size: 18px;
    font-weight: bold;
    color: #262626;
}
</style>
