// 总视图
<template>
    <!-- 任务列表 -->
    <el-card class="blueLine mb30"
             shadow="never">
        <div slot="header">
            <span>任务列表</span>

            <el-dropdown trigger="click"
                         class="flr"
                         style="height:50px">
                <span class="el-dropdown-link">
                    <i class=" el-icon-setting"
                       style="margin:30px 0 0 0;cursor: pointer;font-size:20px"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item class="clearfix">
                        <div @click="addTask">新建任务</div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="warp taskListBox">
            <ul class="taskList">
                <li class="taskItem"
                    v-for="(item,idx) in taskList"
                    :key="idx">
                    <div class="fll">
                        <el-row :gutter="20"
                                class="mb20">
                            <el-col :span="6"> <img src="@/assets/icon/icon_taskFile.png"
                                     class="taskFileImg"
                                     alt=""> {{item.name || ''}}</el-col>
                            <el-col :span="4">
                                <img :src="commonJs.showFileImg(item.executorHeadPic) || require('@/assets/imgs/default_userPhoto.png')"
                                     class="taskFileImg userImg"
                                     alt="">{{item.executorName || ''}}
                            </el-col>
                            <el-col :span="11">{{item.startTimeDeal || ''}} - {{item.endTimeDeal || ''}}</el-col>
                            <el-col :span="3"
                                    class="tar">
                                <el-link v-if="item.status===false"
                                         type="warning"
                                         :underline="false">执行中</el-link>
                                <el-link v-else
                                         type="success"
                                         :underline="false">已完成</el-link>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="16"
                                    class="ellipsis">任务描述：{{item.content || ''}}</el-col>
                            <el-col :span="8"
                                    class="tar">{{item.finishTime || ''}}</el-col>
                        </el-row>
                    </div>
                    <div class="flr">
                        <div class="warp">
                            <span class="details fll"
                                  @click="openpopTaskDetails(item)"></span>

                            <span class="del delete fll"
                                  style="margin-top:-2px"
                                  v-if="item.status"
                                  @click="removeTask(item)"></span>

                            <span class="edit fll"
                                  style="margin-top:-1px"
                                  v-if="!item.status"
                                  @click="deitTask(item)"></span>
                            <span class="del fll"
                                  style="margin-top:-2px"
                                  v-if="!item.status"
                                  @click="deleteTask(item)"></span>
                        </div>
                    </div>

                </li>
            </ul>
        </div>
        <!-- 新建任务 -->
        <popNewTask v-if="popNewTaskVisible"
                    :taskEditInfo="taskEditInfo"
                    @update="getIndexTaskTable"
                    @close="closepopNewTask"></popNewTask>

        <!-- 任务详情 -->
        <pop-task-details v-if="popTaskDetailsVisible"
                          :id="taskEditInfo.id"
                          :item="taskEditInfo"
                          @close="closepopTaskDetails"></pop-task-details>
    </el-card>

</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/generalView.js";
import taskManageAPI from "@/api/taskManage.js";
import popTaskDetails from "@/modleComponents/Task/popTaskDetails";
import popNewTask from "@/views/taskManage/components/popNewTask.vue";
export default {
    name: "comTaskList",

    props: [],

    components: {
        popTaskDetails,
        popNewTask,
    },

    data() {
        return {
            popTaskDetailsVisible: false, // 任务详情弹窗显示
            popNewTaskVisible: false, //新建任务弹框
            taskList: [], //任务列表
            taskEditInfo: {},
        };
    },

    created() {},

    mounted() {
        //任务列表
        this.getIndexTaskTable();
    },

    methods: {
        //获取任务列表
        getIndexTaskTable() {
            let loading = Loading.service({
                target: document.querySelector(".taskListBox"),
            });
            API.getIndexTaskTable({})
                .then((res) => {
                    loading.close();
                    this.taskList = res.content.records;
                })
                .catch(() => {
                    loading.close();
                });
        },

        //移除任务
        removeTask(e) {
            this.$confirm("是否移除该任务?", "", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let loading = Loading.service({
                    target: document.querySelector(".taskListBox"),
                });
                taskManageAPI
                    .changeShowFlag({
                        id:e.id,
                        showFlag:false
                    })
                    .then(() => {
                        loading.close();
                        this.$message({
                            message: "移除成功！",
                            type: "success",
                        });
                        this.getIndexTaskTable();
                    })
                    .catch(() => {
                        loading.close();
                    });
            });
        },

        //删除任务
        deleteTask(e){
            this.$confirm("是否删除该任务?", "", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let loading = Loading.service({
                    target: document.querySelector(".taskListBox"),
                });
                taskManageAPI
                    .delTask(e.id)
                    .then(() => {
                        loading.close();
                        this.$message({
                            message: "删除成功！",
                            type: "success",
                        });
                        this.getIndexTaskTable();
                    })
                    .catch(() => {
                        loading.close();
                    });
            });
        },

        //新建任务
        addTask() {
            this.taskEditInfo = {};
            this.popNewTaskVisible = true;
        },

        // 任务详情 打开弹框
        openpopTaskDetails(e) {
            this.taskEditInfo = e;
            this.popTaskDetailsVisible = true;
        },

        //修改任务
        deitTask(e) {
            this.taskEditInfo = Object.assign({}, e, {
                status: "编辑",
                fatherId: e.supTypeId,
                id: e.id,
            });
            console.log(this.taskEditInfo);
            this.popNewTaskVisible = true;
        },

        // 任务详情 关闭弹框
        closepopTaskDetails() {
            this.popTaskDetailsVisible = false;
            this.taskEditInfo = null;
            this.getIndexTaskTable();
        },
        // 任务详情 关闭弹框
        closepopNewTask() {
            this.popNewTaskVisible = false;
            this.taskEditInfo = null;
        },
    },
    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.generalView ::v-deep .el-card__header {
    padding: 0 30px;
    height: 78px;
    line-height: 78px;
    font-size: 18px;
    font-weight: bold;
    color: #262626;
}
.taskListBox {
    height: 445px;
    overflow-y: auto;
    padding: 0 10px 10px 0;
    box-sizing: border-box;
}
// 任务列表
.taskListBox .el-icon-setting {
    width: 18px;
    height: 16px;
    color: #595959;
    line-height: 78px;
    cursor: pointer;
}
.taskListBox ::v-deep .el-card__body {
    padding: 0 30px 30px;
    height: 478px;
}
.taskListBox .warp {
    padding: 16px 0;
    height: auto;
    overflow-y: auto;
}
.taskList {
    padding-left: 0;
}
.taskList .taskItem {
    width: 100%;
    height: 90px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
    margin-bottom: 14px;
    padding: 15px 0 15px 15px;
    position: relative;
    overflow: hidden;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
}
.taskFileImg {
    width: 18px;
    display: inline-block;
    float: left;
    margin: 0 5px 0px 0;
}

.userImg {
    border-radius: 50%;
}
.taskList .taskItem .flr {
    width: 112px;
    height: 100%;
    position: relative;
}
.taskList .taskItem:after {
    content: "";
    width: 1px;
    height: 90px;
    position: absolute;
    right: 111px;
    top: 0;
    background: #e4e4e5;
}
.taskList .taskItem .fll {
    width: calc(100% - 112px);
    padding-right: 12px;
    box-sizing: border-box;
}
.taskList .taskItem .flr .warp {
    overflow: hidden;
    text-align: center;
    display: inline-block;
    width: 74px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.taskList .taskItem .details,
.taskList .taskItem .edit,
.taskList .taskItem .del {
    width: 18px;
    height: 18px;
    float: left;
    background: url("../../../assets/imgs/icon_details.png") no-repeat center
        center;
    margin-right: 10px;
    cursor: pointer;
}
.taskList .taskItem .edit {
    background: url("../../../assets/imgs/icon_edit.png");
}
.taskList .taskItem .del {
    margin-right: 0;
    background: url("../../../assets/imgs/icon_del.png");
}

.taskList .taskItem .delete {
    width: 18px;
    height: 20px;
    background: url("../../../assets/imgs/icon_delete.png") no-repeat center
        center;
    background-size: 20px 20px;
}
</style>
