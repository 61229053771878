// 总视图
<template>
    <!--今日工时 -->

    <el-card shadow="never"
             class="blueLine ">
        <div slot="header">
            <span>今日工时</span>
        </div>
        <div :class="['todayHoursBox', todayHoursList.length === 0 ? 'emptyBg' : '']"
             id="todayHoursEchart"></div>
    </el-card>

</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/generalView.js";
export default {
    name: "comTodayHours",

    props: [],

    components: {},

    data() {
        return {
            todayHoursList: [], //今日工时统计
        };
    },

    created() {},

    mounted() {
        // 今日工时
        this.getTodayHours();
    },

    methods: {
        //获取今日工时
        getTodayHours() {
            let loading = Loading.service({
                target: document.querySelector(".todayHoursBox"),
            });
            API.getTodayHours()
                .then((res) => {
                    loading.close();
                    this.todayHoursList = res.content;
                    this.todaydayHoursView();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 今日工时
        todaydayHoursView() {
            let xArr = this.todayHoursList.map((v) => v.userName);
            let item1Arr = this.todayHoursList.map((v) => v.workHours);
            // let xArr = [1,2,3];
            // let item1Arr = [1,2,3];
            let todaydayHoursView = this.$echarts.init(
                document.getElementById("todayHoursEchart")
            );
            let option = {
                tooltip: {
                    trigger: "axis",
                    // formatter: "{b} : {c}（h）",
                },
                legend: {
                    show: false,
                },
                grid: {
                    top: "15%",
                    bottom: "5%",
                    left: "5%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: xArr,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#ddd",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#ddd"],
                                width: 1,
                                type: "solid",
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#000000",
                                fontSize: "14px",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        axisTick: {
                            show: false,
                        },
                        type: "value",
                        name: "",
                        // minInterval: 1,
                        // nameRotate: '0.1',
                        // minInterval: 1,
                        nameTextStyle: {
                            //y轴上方单位的颜色
                            color: "#000",
                            fontSize: "12",
                            fontWeight: "600",
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#ddd",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#ddd"],
                                width: 1,
                                type: "solid",
                            },
                        },
                        axisLabel: {
                            formatter: "{value}（h）",
                            textStyle: {
                                color: "#000000",
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "bar",
                        data: item1Arr,
                        barMaxWidth: 14,
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    1,
                                    0,
                                    0,
                                    [
                                        {
                                            offset: 0,
                                            color: "#3DA4FC", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "#50C6FF", // 100% 处的颜色
                                        },
                                    ],
                                    false
                                ),
                                //柱形图圆角，初始化效果
                                barBorderRadius: [15, 15, 15, 15],
                                label: {
                                    show: true, //开启显示
                                    formatter: function (params) {
                                        if (params.value > 0) {
                                            return params.value + "（h）";
                                        } else {
                                            return " ";
                                        }
                                    }, //显示百分号
                                    position: "top", //在上方显示
                                    textStyle: {
                                        //数值样式
                                        color: "#17C9B8",
                                        fontSize: 14,
                                    },
                                },
                            },
                        },
                    },
                ],
            };
            todaydayHoursView.setOption(option);
        },
    },
    computed: {
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// 项目机会
.itemOpportunityBox ::v-deep .el-card__body {
    padding-bottom: 30px;
    overflow: hidden;
}
.itemOpportunity {
    width: 246px;
    height: 142px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
}
#itemOpportunityEchart1,
.itemOpportunityEchart1 {
    height: 305px;
}
.fullBox {
    width: 100%;
    height: 100%;
    z-index: 3;
}
.relativeBox {
    position: relative;
}
#itemEchart1,
.itemEchart2 {
    width: 246px;
    height: 305px;
    background: #ffffff;
    border: 1px solid #e4e4e5;
    border-radius: 2px;
}
#todayHoursEchart {
    height: 305px;
}
.centerTipsBox {
    position: absolute;
    font-size: 20px;
    color: #000;
    width: 100%;
    text-align: center;
    z-index: 0;
}
.centerTipsBox.tipsone {
    top: 37%;
    left: 0%;
}
.centerTipsBox.tipstwo {
    top: 43%;
    left: -15%;
}

.centerTipsBox.tipsThree {
    top: 37%;
    left: 40%;
    z-index: 4;
    width: 20%;
}
.dwSmall {
    font-size: 16px;
}
</style>
