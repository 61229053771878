// 总视图
<template>
    <!-- 今日数据统计 -->
    <el-card shadow="never"
             class="blueLine todayDataBox">
        <div slot="header">
            <span>今日数据统计</span>
        </div>
        <ul class="todayData">
            <li v-for="(item,idx) in todayData.baseInfo"
                :key="idx">
                <div class="count">{{todayData.data[item.value] || 0}} </div>
                <div class="name">{{item.label}}<span style="font-size:12px">{{item.label === '收款' || item.label === '付款' ? '(元)' :''}}</span></div>
            </li>
        </ul>
    </el-card>

</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/generalView.js";
export default {
    name: "comTodayData",

    props: [],

    components: {},

    data() {
        return {

            todayData: {
                baseInfo: [
                    {
                        label: "新增项目机会",
                        value: "reserveProjectCount",
                    },
                    {
                        label: "搁置项目机会",
                        value: "shelveProjectCount",
                    },
                    {
                        label: "新增项目",
                        value: "projectCount",
                    },
                    {
                        label: "废止项目",
                        value: "cancelProjectCount",
                    },
                    {
                        label: "签订合同",
                        value: "signContractCount",
                    },
                    {
                        label: "收款",
                        value: "collection",
                    },
                    {
                        label: "付款",
                        value: "payment",
                    },
                    {
                        label: "处理事务",
                        value: "processTransactionCount",
                    },
                ],
                data: {},
            }, // 今日数据统计
        };
    },

    created() {},

    mounted() {
        // 今日数据统计
        this.getTodayData();
    },

    methods: {
        // 今日数据统计
        getTodayData() {
            let loading = Loading.service({
                target: document.querySelector(".todayDataBox"),
            });
            API.getTodayData()
                .then((res) => {
                    loading.close();
                    this.todayData.data = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
    },
    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.generalView ::v-deep .el-card__header {
    padding: 0 30px;
    height: 78px;
    line-height: 78px;
    font-size: 18px;
    font-weight: bold;
    color: #262626;
}
// 今日数据统计
.todayDataBox .todayData {
    overflow: hidden;
}
.todayDataBox .todayData li {
    float: left;
    text-align: center;
    margin-right: 23px;
    width: calc(12.5% - 23px);
}
.todayDataBox .todayData .count {
    font-size: 24px;
    font-family: DIN;
    font-weight: 500;
    color: #00a2e9;
    margin-bottom: 20px;
}
.todayDataBox .todayData .name {
    font-size: 14px;
    font-weight: 400;
    color: #595959;
}
</style>
